<template>
  <li class="nav-item" :class="{ dropdown: hasChild }">
    <router-link :to="data.to" class="nav-link" active-class="active" v-if="!hasChild">
      <i><font-awesome-icon :icon="data.icon" /></i>
      <span class="link-title ml-2">{{ data.title }}</span>
    </router-link>
    <div v-else>
      <a class="nav-link dropdown-toggle" href="javascript:;" data-toggle="dropdown" data-bs-auto-close="false" role="button" aria-expanded="true">
        <i><font-awesome-icon :icon="data.icon" /></i>
        <span class="nav-link-title ml-2">{{ data.title }}</span>
      </a>
      <div class="dropdown-menu" :class="{ show: isActive(data.to) }" data-bs-popper="none">
        <div class="dropdown-menu-columns">
          <div class="dropdown-menu-column">
            <router-link v-for="(item, index) in data.child" :key="index" :to="item.to" class="dropdown-item" :active-class="isActiveChild(item.to) ? 'active' : ''">
              {{ item.title }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import slugify from 'slugify';

export default {
  name: 'SidebarItem',
  props: ['data'],
  computed: {
    hasChild() {
      return this.data.child && this.data.child.length > 0;
    },
  },
  methods: {
    id: (s) => slugify(s),
    isActive(link) {
      return this.$route.fullPath.includes(link);
    },
    isActiveChild(link) {
      return this.$route.path === link;
    },
  },
};
</script>
<style>
.custom-icon {
  font-size: 16px;
}
</style>
